import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const PageSEO = ({ title, description, image, url }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        data {
          seo_title
          seo_description {
            text
          }
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={`${
        title !== null
          ? `${title} — ${data.prismicSeo.data.seo_title}`
          : data.prismicSeo.data.seo_title
      }`}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: "description",
          content:
            description !== null
              ? description
              : `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "og:image",
          content:
            image !== null ? image : `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:image:secure_url",
          content:
            image !== null ? image : `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:description",
          content:
            description !== null
              ? description
              : `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "og:title",
          content: `${
            title !== null
              ? `${title} — ${data.prismicSeo.data.seo_title}`
              : data.prismicSeo.data.seo_title
          }`,
        },
        {
          name: "twitter:title",
          content: `${
            title !== null
              ? `${title} — ${data.prismicSeo.data.seo_title}`
              : data.prismicSeo.data.seo_title
          }`,
        },
        {
          name: "twitter:description",
          content:
            description !== null
              ? description
              : `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content:
            image !== null ? image : `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          property: "og:url",
          content: url,
        },
        {
          property: "twitter:url",
          content: url,
        },
      ]}
    />
  );
};
