import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { RemoveScroll } from "react-remove-scroll";

// Utils
import kebabCase from "just-kebab-case";

const PanelContainer = styled.div`
  position: fixed;
  background-color: #fff;
  color: #000;

  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
  transition: 600ms transform ease;

  padding: 35px 40px;
  overflow-y: scroll;

  &.size-full {
    height: ${(props) => props.height}px;
  }

  &.size-half {
    height: ${(props) => props.height / 2}px;
  }

  cursor: url(/icons/close@1x.png) 0 0, pointer;
  cursor: -webkit-image-set(
        url(/icons/close@1x.png) 1x,
        url(/icons/close@2x.png) 2x
      )
      0 0,
    pointer;

  &.open {
    transform: translateY(0);
  }

  &.closed {
    transform: translateY(100%);
  }

  & .grid {
    display: grid;
    grid-column-gap: 40px;
  }

  & .grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  & .additional-info-container {
    grid-column: 1 / 3;

    & p {
      margin: 0;
    }
  }

  & .text-container {
    grid-column: 3 / 7;
    max-width: 890px;
    margin: 0 0 0 auto;

    & p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 18px;

    & .grid {
      display: block;
      grid-column-gap: unset;
    }

    & .grid-6 {
      grid-template-columns: unset;
    }

    & .additional-info-container {
      margin: 0 0 1em 0;
    }
  }
`;

const ClosePanelContainer = styled.button`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 11;

  cursor: url(/icons/close@1x.png) 0 0, pointer;
  cursor: -webkit-image-set(
        url(/icons/close@1x.png) 1x,
        url(/icons/close@2x.png) 2x
      )
      0 0,
    pointer;

  &.visible {
    display: block;
    visibility: visible;
  }

  &.hidden {
    display: none;
    visibility: hidden;
  }
`;

export const Panel = ({
  title,
  text,
  additionalInfo,
  isPanelOpen,
  setIsPanelOpen,
  height,
  size,
}) => {
  return (
    <RemoveScroll>
      <ClosePanelContainer
        onClick={() => setIsPanelOpen(false)}
        className={isPanelOpen ? `visible` : `hidden`}
      />

      <PanelContainer
        className={`${isPanelOpen ? `open` : `closed`} size-${kebabCase(size)}`}
        height={height}
        onClick={() => setIsPanelOpen(false)}
        id="panel"
      >
        <div className="grid grid-6">
          <div className="additional-info-container">
            <PrismicRichText field={title} />
            <PrismicRichText field={additionalInfo} />
          </div>

          <div className="text-container">
            <PrismicRichText field={text} />
          </div>
        </div>
      </PanelContainer>
    </RemoveScroll>
  );
};
