import React from "react";

export const PlusIcon = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.89941 19.7991V8.39114e-05" stroke={fill} strokeWidth="2" />
    <path d="M0 9.89954L19.799 9.89954" stroke={fill} strokeWidth="2" />
  </svg>
);
