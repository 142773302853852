import React from "react";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ location, deviceHasHover }) => {
  if (deviceHasHover) {
    return <DesktopMenu location={location} />;
  } else {
    return <MobileMenu location={location} />;
  }
};
