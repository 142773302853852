import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

// Utils
import kebabCase from "just-kebab-case";

export const SingleImage = ({ data, image, imageSize, index }) => {
  return (
    <div
      className={`image-slide image-size-${kebabCase(
        imageSize !== null ? imageSize : `Padded`
      )}`}
    >
      {image.gatsbyImageData !== null && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={
            image.alt !== null
              ? image.alt
              : data.title !== undefined
              ? data.title.text
              : ""
          }
          fetchpriority={index <= 3 ? `high` : `low`}
          loading={index <= 3 ? `eager` : `lazy`}
        />
      )}
    </div>
  );
};
