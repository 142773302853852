import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = () => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        data {
          seo_title
          seo_description {
            text
          }
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={data.prismicSeo.data.seo_title}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
        },
        {
          name: "og:title",
          content: `${data.prismicSeo.data.seo_title}`,
        },
        {
          name: "description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "og:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:image:secure_url",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "twitter:title",
          content: `${data.prismicSeo.data.seo_title}`,
        },
        {
          name: "twitter:description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
      ]}
      link={[
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/henrycoleman",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/henrycoleman",
        },
        {
          rel: "preload",
          href: "/fonts/AUTHENTICSans-90.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/AUTHENTICSans-90.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
      ]}
    />
  );
};
