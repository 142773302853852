import React, { useRef, useState } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { useKeyPressEvent } from "react-use";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// // Components
// import { Panel } from "../project/panel";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  width: 100%;
  height: 100%;
  /* padding: 90px 0 120px 0; */

  /* background-color: ${(props) => props.backgroundColor}; */

  /* background-color: #fff0f0; */

  z-index: 10;

  & .image-slide {
    position: relative;
    z-index: 1;

    &.image-size-full-bleed {
      height: ${(props) => props.height}px;

      & img {
        height: ${(props) => props.height}px;
        object-fit: cover;
      }
    }

    &.image-size-padded {
      max-height: calc(${(props) => props.height}px - 120px - 120px);
      padding: 120px 0;

      & img {
        max-height: calc(${(props) => props.height}px - 120px - 120px);
        object-fit: contain !important;
      }
    }
  }

  & .react-slideshow-container,
  & .react-slideshow-wrapper,
  & .images-wrap,
  & .images-wrap > div,
  & .image-slide,
  & .video-slide {
    position: relative;

    width: 100%;
    height: 100%;
    /* max-height: calc(${(props) => props.height}px - 90px - 120px); */
  }

  & .image-slide > div {
    /* height: calc(${(props) => props.height}px - 90px - 120px); */
  }

  & .gatsby-image-wrapper {
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;

    /* max-height: calc(${(props) => props.height}px - 90px - 120px); */

    padding: 90px 0;

    /* object-fit: contain !important; */

    @media (max-width: 860px) {
      padding: 0;
      /* object-fit: cover !important; */
    }
  }

  & .react-slideshow-slide-images-wrap {
    align-items: center;
  }

  & .caption {
    padding: 30px;
    text-align: center;

    & p {
      margin: 0;
    }

    & a {
      color: rgba(153, 153, 153, 1);
    }

    &.top {
      display: grid;
      grid-template-columns: 20px 1fr 20px;
      grid-column-gap: 30px;
      align-items: baseline;

      & .close-button {
        & .desktop-close-icon {
          display: block;

          @media (max-width: 860px) {
            display: none;
          }
        }

        & .mobile-close-icon {
          display: none;

          @media (max-width: 860px) {
            display: block;
          }
        }
      }

      @media (max-width: 860px) {
        grid-template-columns: 14px 1fr 14px;
      }
    }

    @media (max-width: 860px) {
      padding: 20px;
    }
  }

  @media (max-width: 860px) {
    padding: 0 0 0 0;

    max-height: 100%;

    & .react-slideshow-container,
    & .react-slideshow-wrapper,
    & .images-wrap,
    & .images-wrap > div,
    & .image-slide,
    & .video-slide,
    & .image-slide > div,
    & img {
      height: 100%;
      max-height: 100%;

      object-fit: cover !important;
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 120px;
  bottom: 120px;
  width: 50%;

  z-index: 10;

  &.previous-arrow {
    left: 0;
  }

  &.next-arrow {
    right: 0;
  }
`;

export const Gallery = ({
  media,
  isPanelOpen,
  setIsPanelOpen,
  currentGallerySlide,
  setCurrentGallerySlide,
}) => {
  const updateCurrentSlide = (index) => {
    if (currentGallerySlide !== index) {
      setCurrentGallerySlide(index);
    }
  };

  // Window Height
  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  return (
    <>
      <GalleryContainer
        className={`gallery`}
        height={height}
        id="artworks-container"
        onClick={() => {
          if (isPanelOpen) {
            setIsPanelOpen(false);
          }
        }}
      >
        {media.length > 1 ? (
          <>
            {media.length > 1 && (
              <Arrow
                aria-label="Previous Slide"
                className={`previous-arrow arrow`}
                onClick={() => {
                  slideRef.current.goBack();
                }}
              />
            )}

            <Slide
              ref={slideRef}
              arrows={false}
              transitionDuration={750}
              duration={8000}
              pauseOnHover={false}
              autoplay={true}
              infinite={true}
              canSwipe={false}
              defaultIndex={0}
              onChange={(previous, next) => updateCurrentSlide(next)}
            >
              {media}
            </Slide>

            {media.length > 1 && (
              <Arrow
                aria-label="Next Slide"
                className={`next-arrow arrow`}
                onClick={() => {
                  slideRef.current.goNext();
                }}
              />
            )}
          </>
        ) : (
          <div>{media}</div>
        )}
      </GalleryContainer>
    </>
  );
};
