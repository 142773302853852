import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";

// Fullpage
import ReactFullpage from "@fullpage/react-fullpage";

// Components
import { Panel } from "../project/panel";
import { PageSEO } from "../seo/page-seo";
import { PlusIcon } from "../icons/icons";
import { SingleHomepageProject } from "../project/single-homepage-project";

const Page = styled.div`
  background-color: ${(props) => props.bgColor};
  transition: 300ms background-color linear;
  transition-delay: 800ms;

  & .fp-section {
    height: ${(props) => props.height}px;
    overflow: hidden;
  }

  & .gatsby-image-wrapper {
    height: ${(props) => props.height}px;
  }

  & .slide {
    height: ${(props) => props.height}px;
    overflow: hidden;

    & .fp-overflow {
      height: ${(props) => props.height}px;
      overflow: hidden;
    }
  }

  & .image-slide {
    position: relative;
    z-index: 1;

    &.image-size-full-bleed {
      height: ${(props) => props.height}px;

      & img {
        height: ${(props) => props.height}px;
        object-fit: cover;
      }
    }

    &.image-size-padded {
      max-height: calc(${(props) => props.height}px - 120px - 120px);
      padding: 120px;

      & img {
        max-height: calc(${(props) => props.height}px - 120px - 120px);
        object-fit: contain !important;
      }

      @media (max-width: 900px) {
        max-height: calc(${(props) => props.height}px - 60px - 60px);
        padding: 60px;

        & img {
          max-height: calc(${(props) => props.height}px - 60px - 60px);
        }
      }

      @media (max-width: 600px) {
        max-height: calc(${(props) => props.height}px - 20px - 20px);
        padding: 20px 0;

        & img {
          max-height: calc(${(props) => props.height}px - 20px - 20px);
        }
      }
    }
  }

  & .fp-arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    height: 100%;
    width: 50%;

    z-index: 10;

    margin: 0;
    transform: unset;
    user-select: unset;

    &.fp-prev,
    &.fp-next {
      border-width: unset;
      border-color: unset;
      border: 0;
    }
  }
`;

export const ProjectsMode = ({ data, isPrismicPreview }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [currentInnerSlide, setCurrentInnerSlide] = useState(0);
  const [currentOuterSlide, setCurrentOuterSlide] = useState(0);
  const [outerFullpageApi, setOuterFullpageApi] = useState(undefined);

  // Window Height
  const height = use100vh();

  const projects = data.prismicHome.data.projects.filter(
    (project) => project.project.document !== null
  );

  const [colorPalette, getColorPalette] = useState([]);
  const [averageLuminance, getAverageLuminance] = useState([]);
  const fetchColorPalette = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };

  const getColorPalettes = (url) => {
    fetchColorPalette(url)
      .then((res) => {
        getColorPalette(res.dominant_colors.muted_light.hex);
        getAverageLuminance(res.average_luminance);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const media = projects.map((project, index) => (
    <SingleHomepageProject
      key={`single_homepage_project_${index}_${project.project.document.id}`}
      id={project.project.document.id}
      data={project.project.document.data}
      outerIndex={index}
      height={height}
      currentInnerSlide={currentInnerSlide}
      currentOuterSlide={currentOuterSlide}
      outerFullpageApi={outerFullpageApi}
      numberOfProjects={projects.length}
      colorPalette={colorPalette}
      getColorPalettes={getColorPalettes}
    />
  ));

  useEffect(() => {
    if (outerFullpageApi !== undefined) {
      if (isPanelOpen) {
        outerFullpageApi.setAllowScrolling(false);
      } else {
        outerFullpageApi.setAllowScrolling(true);
      }
    }
  }, [isPanelOpen, outerFullpageApi]);

  useEffect(() => {
    console.log(averageLuminance);
    if (averageLuminance > 0.55) {
      document.body.classList.remove(`light-palette`);
      document.body.classList.add(`dark-palette`);
    } else {
      document.body.classList.remove(`dark-palette`);
      document.body.classList.add(`light-palette`);
    }
  }, [averageLuminance]);

  return (
    <>
      <PageSEO
        title={null}
        description={null}
        image={null}
        url={data.prismicHome.url}
      />

      <Page
        height={height}
        bgColor={colorPalette !== undefined ? colorPalette : `#fff0f0`}
        className={averageLuminance > 0.55 ? `dark-palette` : `light-palette`}
      >
        <ReactFullpage
          normalScrollElements={`#panel`}
          loopHorizontal={false}
          resetSliders={true}
          slidesNavigation={false}
          credits={false}
          touchSensitivity={3}
          scrollingSpeed={1000}
          scrollOverflow={false}
          scrollOverflowReset={true}
          beforeLeave={(origin, destination, direction, trigger) => {
            setCurrentInnerSlide(0);
          }}
          onLeave={(origin, destination, direction, trigger) => {
            setCurrentOuterSlide(destination.index);
          }}
          onSlideLeave={(section, origin, destination, direction, trigger) => {
            setCurrentInnerSlide(destination.index);
          }}
          render={({ state, fullpageApi }) => {
            setOuterFullpageApi(fullpageApi);

            return <ReactFullpage.Wrapper>{media}</ReactFullpage.Wrapper>;
          }}
        />

        <div
          className={`caption-container ${
            isPrismicPreview ? `layout-preview` : ``
          }`}
        >
          <button onClick={() => setIsPanelOpen(true)} type="button">
            <span>
              <PrismicRichText
                field={
                  projects[currentOuterSlide].project.document.data.title
                    .richText
                }
              />
            </span>

            <PlusIcon fill={`#000`} />
          </button>
        </div>

        <Panel
          title={
            projects[currentOuterSlide].project.document.data.title.richText
          }
          additionalInfo={
            projects[currentOuterSlide].project.document.data.additional_info
              .richText
          }
          text={projects[currentOuterSlide].project.document.data.text.richText}
          size={
            projects[currentOuterSlide].project.document.data
              .information_panel_size
          }
          isPanelOpen={isPanelOpen}
          setIsPanelOpen={setIsPanelOpen}
          height={height}
        />
      </Page>
    </>
  );
};
