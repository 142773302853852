import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Prismic
import { linkResolver } from "./src/components/utils/linkResolver";

// Pages
import Project from "./src/pages/{PrismicProject.url}";
import About from "./src/pages/about";
import Index from "./src/pages/index";
import Projects from "./src/pages/projects";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          project: Project,
          about: About,
          home: Index,
          projects: Projects,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
