import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Utils
import kebabCase from "just-kebab-case";

// Styles
import "../styles/plyr.css";

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  position: relative;
  z-index: 10;

  & .plyr {
    border-radius: 0;
    flex: 1;
  }

  & .youtube-video-container {
    position: relative;

    width: 100%;
    padding-bottom: 56.25%;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
`;

export const SingleVideo = ({ video, id, videoSize }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-${id}`, {
          debug: false,
          autoplay: true,
          autopause: true,
          loop: { active: true },
          hideControls: true,
          clickToPlay: true,
          muted: true,
          volume: 0,
          fullscreen: { enabled: true, fallback: true, iosNative: true },
          vimeo: {
            autopause: false,
            byline: false,
            controls: false,
            referrerPolicy: "strict-origin-when-cross-origin",
          },
          youtube: {
            playsinline: true,
          },
        })
      );
    }
  }, []);

  if (video !== null) {
    return (
      <VideoContainer
        className={`video-slide video-size-${kebabCase(
          videoSize !== null ? videoSize : `Padded`
        )}`}
      >
        <div
          className="plyr__video-embed"
          id={`video-${id}`}
          dangerouslySetInnerHTML={{
            __html: video.html,
          }}
        />
      </VideoContainer>
    );
  } else {
    return null;
  }
};
