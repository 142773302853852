import React, { useState } from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, data, children }) => {
  // Media Query
  let deviceHasHover = useMediaQuery("(hover: hover) and (pointer: fine)");

  return (
    <>
      <Header location={location} deviceHasHover={deviceHasHover} />
      <Main>{children}</Main>
    </>
  );
};
