import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import { SingleImage } from "./single-image";

const SingleHomepageProjectContainer = styled.div``;

const ImageSlide = styled.div``;

const NextArtworkArrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  height: 100%;
  width: ${(props) => props.width}vw;

  z-index: 10;

  &.active {
    display: block;
  }

  &.hidden {
    display: none;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;

    &.active,
    &.hidden {
      display: none;
    }
  }
`;

const PreviousArtworkArrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  height: 100%;
  width: ${(props) => props.width}vw;

  z-index: 10;

  &.active {
    display: block;
  }

  &.hidden {
    display: none;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;

    &.active,
    &.hidden {
      display: none;
    }
  }
`;

export const SingleHomepageProject = ({
  data,
  id,
  currentOuterSlide,
  currentInnerSlide,
  outerFullpageApi,
  numberOfProjects,
  colorPalette,
  outerIndex,
}) => {
  const [colorData, setColorData] = useState([]);

  const fetchColorPalette = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };

  const getColorPalettes = async (url, type) => {
    if (type === `image`) {
      try {
        const res = await fetchColorPalette(url);
        console.log(res);
        return {
          imageColor:
            res?.dominant_colors?.vibrant?.hex !== undefined
              ? res?.dominant_colors?.vibrant?.hex
              : res?.dominant_colors?.vibrant_light?.hex,
          imageLuminance: res.average_luminance,
        };
      } catch (e) {
        console.log(e.message);
        return null;
      }
    } else {
      return {
        imageColor: `#fff0f0`,
        imageLuminance: 1,
      };
    }
  };

  useEffect(() => {
    const fetchColors = async () => {
      const colorPromises = data.body
        .filter((item) => {
          if (item.slice_type === `image`) {
            if (item.primary.image.url !== null) {
              return item;
            } else {
              return null;
            }
          } else {
            return item;
          }
        })
        .filter((item) => item !== null)
        .map((item) => {
          if (item.slice_type === `image`) {
            return getColorPalettes(
              `${item.primary.image.url}&palette=json`,
              `image`
            );
          } else {
            return getColorPalettes(``, `video`);
          }
        });

      const colors = await Promise.all(colorPromises);
      setColorData(colors.filter((color) => color !== null));
    };

    fetchColors();
  }, [data]);

  useEffect(() => {
    if (colorData[currentInnerSlide] !== undefined) {
      if (colorData[currentInnerSlide].imageLuminance > 0.5) {
        document.body.classList.remove(`light-palette`);
        document.body.classList.add(`dark-palette`);
      } else {
        document.body.classList.remove(`dark-palette`);
        document.body.classList.add(`light-palette`);
      }
    }
  }, [currentInnerSlide, colorData]);

  const slides = data.body
    .filter((item) => {
      if (item.slice_type === `image`) {
        if (item.primary.image.url !== null) {
          return item;
        } else {
          return null;
        }
      } else {
        return item;
      }
    })
    .filter((item) => item !== null)
    .map((item, index) => {
      if (item.slice_type === `image`) {
        return (
          <div className="slide" key={`single_image_${index}_${id}`}>
            <SingleImage
              data={data}
              image={item.primary.image}
              imageSize={item.primary.image_size}
              id={id}
              index={index}
              currentInnerSlide={currentInnerSlide}
              colorPalette={colorPalette}
              currentOuterSlide={currentOuterSlide}
              outerIndex={outerIndex}
            />
          </div>
        );
      }
    });

  return (
    <SingleHomepageProjectContainer className="section" id={id}>
      <PreviousArtworkArrow
        onClick={() => outerFullpageApi.moveSectionUp()}
        type="button"
        width={
          currentInnerSlide === 0 && currentOuterSlide === numberOfProjects - 1
            ? `100`
            : `50`
        }
        className={
          (currentInnerSlide === 0 && currentOuterSlide !== 0) ||
          (currentInnerSlide === 0 &&
            currentOuterSlide === numberOfProjects - 1)
            ? `active previous-artwork-arrow`
            : `hidden previous-artwork-arrow`
        }
      />

      {slides}

      <NextArtworkArrow
        onClick={() => outerFullpageApi.moveSectionDown()}
        type="button"
        width={currentInnerSlide === 0 ? `100` : `50`}
        className={
          currentInnerSlide === slides.length - 1 &&
          currentOuterSlide !== numberOfProjects - 1
            ? `active next-artwork-arrow`
            : `hidden next-artwork-arrow`
        }
      />
    </SingleHomepageProjectContainer>
  );
};
