import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 600ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}


.fp-watermark{
  display: none;
}

body {
  font-family: "AuthenticSans", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 19px;
  line-height: 28px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
}

body {
    &.light-palette {

    & header {
      color: #fff;
    }

    & .caption-container{
        & button {
          color: #fff;
        }

      & svg {
        & path {
          stroke: #fff;
        }
      }
    }





    & .previous-artwork-arrow {
    cursor: url(/icons/arrow-up-white@1x.png) 0 0, n-resize !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-up-white@1x.png) 1x,
          url(/icons/arrow-up-white@2x.png) 2x
        )
        0 0,
      n-resize !important;
  }

    & .next-artwork-arrow {
      cursor: url(/icons/arrow-down-white@1x.png) 0 0, s-resize !important;
  cursor: -webkit-image-set(
        url(/icons/arrow-down-white@1x.png) 1x,
        url(/icons/arrow-down-white@2x.png) 2x
      )
      0 0,
    s-resize !important;
  }

    & .previous-arrow,
    & .fp-prev {
      left: 0;
      cursor: url(/icons/arrow-prev-white@1x.png) 0 0, w-resize !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-prev-white@1x.png) 1x,
            url(/icons/arrow-prev-white@2x.png) 2x
          )
          0 0,
        w-resize !important;
    }

    & .next-arrow,
    & .fp-next {
      right: 0;
      cursor: url(/icons/arrow-next-white@1x.png) 0 0, e-resize !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-next-white@1x.png) 1x,
            url(/icons/arrow-next-white@2x.png) 2x
          )
          0 0,
        e-resize !important;
    }
  }
  &.dark-palette {
    & header {
      color: #000;
    }

    & .caption-container{
      & button {
        color: #000;
      }

      & svg {
        & path {
          stroke: #000;
        }
      }
    }


    & .previous-artwork-arrow {
    cursor: url(/icons/arrow-up-black@1x.png) 0 0, n-resize !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-up-black@1x.png) 1x,
          url(/icons/arrow-up-black@2x.png) 2x
        )
        0 0,
      n-resize !important;
  }


  & .next-artwork-arrow {
      cursor: url(/icons/arrow-down-black@1x.png) 0 0, s-resize !important;
  cursor: -webkit-image-set(
        url(/icons/arrow-down-black@1x.png) 1x,
        url(/icons/arrow-down-black@2x.png) 2x
      )
      0 0,
    s-resize !important;
  }

  & .previous-arrow,
    & .fp-prev {
      left: 0;
      cursor: url(/icons/arrow-prev-black@1x.png) 0 0, w-resize !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-prev-black@1x.png) 1x,
            url(/icons/arrow-prev-black@2x.png) 2x
          )
          0 0,
        w-resize !important;
    }

    & .next-arrow,
    & .fp-next {
      right: 0;
      cursor: url(/icons/arrow-next-black@1x.png) 0 0, e-resize !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-next-black@1x.png) 1x,
            url(/icons/arrow-next-black@2x.png) 2x
          )
          0 0,
        e-resize !important;
    }
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-size: 19px;
  line-height: 28px;

  font-family: "AuthenticSans", sans-serif;
  font-weight: normal;

  margin: 0;


  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

.gatsby-image-wrapper-constrained{
  width: 100%;
}

.caption-container {
  position: fixed;

  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10;

  padding: 35px 40px;

  &.layout-preview{
    & button{
      margin: 0 0 0 auto;
    }
  }

  & button {
    display: flex;
    align-items: center;

    & span {
      margin: 0 10px 0 0;
    }

    & svg {
      opacity :0;
      transition:600ms opacity ease;

      margin: 0 0 4px 0;
    }

    &:hover{
      & svg {
        opacity:1;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 18px;
  }
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    cursor:pointer;
    background-color: transparent;
    appearance:none;

    border:0;
    padding:0;
    margin:0;
    line-height: 1.1;
    color: inherit;
    overflow: visible;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}
`;

export default GlobalStyle;
