import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 35px 0;
  padding: 0 40px;

  height: calc(${(props) => props.height}px - 35px - 35px);

  & .grid {
    display: grid;
    grid-column-gap: 40px;

    height: 100%;
  }

  & .grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  & .thumbnail-container {
    grid-column: 1 / 3;

    margin: 80px 0 0 0;

    align-self: flex-end;
  }

  & .projects-container {
    grid-column: 3 / 7;

    & ol {
      & li {
        padding: 0 0 5px 0;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 80px 0 0 0;
    padding: 0 18px;

    & .thumbnail-container {
      display: none;
    }

    & .grid {
      display: block;
      grid-column-gap: unset;
    }

    & .grid-6 {
      grid-template-columns: unset;
    }

    & .projects-container {
      grid-column: 3 / 7;

      & ol {
        & li {
          padding: 0;
        }
      }
    }
  }
`;

const Projects = ({ data }) => {
  const [activeProject, setActiveProject] = useState(null);
  const [activeProjectIndex, setActiveProjectIndex] = useState(0);

  // Window Height
  const height = use100vh();

  useEffect(() => {
    document.body.classList.remove(`light-palette`);
    document.body.classList.remove(`dark-palette`);
  }, []);

  const content = data.prismicProjects.data.projects
    .filter((project) => project.project.document !== null)
    .map((project, index) => (
      <li
        key={`single_project_${index}_${project.project.document.id}`}
        onMouseEnter={() => {
          setActiveProject(project.project.document.data);
          // setActiveProjectIndex(index);
        }}
        onMouseLeave={() => {
          setActiveProject(null);
          // setActiveProjectIndex(0);
        }}
      >
        <Link to={project.project.document.url}>
          {project.project.document.data.title.text}
        </Link>
      </li>
    ));

  return (
    <>
      <PageSEO
        title={data.prismicProjects.data.title.text}
        description={null}
        image={null}
        url={data.prismicProjects.url}
      />

      <Page height={height}>
        <div className="grid grid-6">
          <div className="thumbnail-container">
            {activeProject !== null && (
              <>
                {activeProject.thumbnail.gatsbyImageData !== null && (
                  <GatsbyImage
                    image={activeProject.thumbnail.gatsbyImageData}
                    alt={activeProject.thumbnail.alt}
                    loading={`lazy`}
                  />
                )}
              </>
            )}
          </div>
          <div className="projects-container">
            <ol>{content}</ol>
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Projects);

export const query = graphql`
  {
    prismicProjects {
      _previewable
      url
      id
      data {
        title {
          text
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    text
                  }
                  thumbnail {
                    alt
                    gatsbyImageData(width: 600, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
