import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { Gallery } from "../components/images/gallery";
import { SingleImage } from "../components/project/single-image";
import { SingleVideo } from "../components/project/single-video";
import { Panel } from "../components/project/panel";
import { PageSEO } from "../components/seo/page-seo";
import { PlusIcon } from "../components/icons/icons";

const Page = styled.div`
  & .fp-section {
    height: ${(props) => props.height}px;
    overflow: hidden;
  }

  & .gatsby-image-wrapper {
    height: ${(props) => props.height}px;
  }

  & .slide {
    height: ${(props) => props.height}px;
    overflow: hidden;

    & .fp-overflow {
      height: ${(props) => props.height}px;
      overflow: hidden;
    }
  }

  & .video-slide,
  & .image-slide {
    position: relative;
    z-index: 1;

    &.video-size-full-bleed,
    &.image-size-full-bleed {
      height: ${(props) => props.height}px;

      & img {
        height: ${(props) => props.height}px;
        object-fit: cover;
      }
    }

    &.video-size-padded,
    &.image-size-padded {
      max-height: calc(${(props) => props.height}px - 120px - 120px);
      padding: 120px;

      & img {
        max-height: calc(${(props) => props.height}px - 120px - 120px);
        object-fit: contain !important;
      }

      @media (max-width: 900px) {
        max-height: calc(${(props) => props.height}px - 60px - 60px);
        padding: 60px;

        & img {
          max-height: calc(${(props) => props.height}px - 60px - 60px);
        }
      }

      @media (max-width: 600px) {
        max-height: ${(props) => props.height}px;
        padding: 20px;

        & img {
          max-height: calc(${(props) => props.height}px - 20px - 20px);
        }
      }
    }
  }

  & .fp-arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    height: 100%;
    width: 50%;

    z-index: 10;

    margin: 0;
    transform: unset;
    user-select: unset;

    &.fp-prev,
    &.fp-next {
      border-width: unset;
      border-color: unset;
      border: 0;
    }
  }
`;

const Project = ({ data, isPrismicPreview }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [currentGallerySlide, setCurrentGallerySlide] = useState(0);
  const [colorData, setColorData] = useState([]);

  // Window Height
  const height = use100vh();

  const fetchColorPalette = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };

  const getColorPalettes = async (url, type) => {
    if (type === `image`) {
      try {
        const res = await fetchColorPalette(url);
        return {
          imageColor:
            res?.dominant_colors?.vibrant?.hex !== undefined
              ? res?.dominant_colors?.vibrant?.hex
              : res?.dominant_colors?.vibrant_light?.hex,
          imageLuminance: res.average_luminance,
        };
      } catch (e) {
        console.log(e.message);
        return null;
      }
    } else {
      return {
        imageColor: `#fff0f0`,
        imageLuminance: 1,
      };
    }
  };

  useEffect(() => {
    if (data.prismicProject.data.body.length > 0) {
      const fetchColors = async () => {
        const colorPromises = data.prismicProject.data.body.map((item) => {
          if (item.slice_type === `image`) {
            return getColorPalettes(
              `${item.primary.image.url}&palette=json`,
              `image`
            );
          } else {
            return getColorPalettes(``, `video`);
          }
        });

        const colors = await Promise.all(colorPromises);
        setColorData(colors.filter((color) => color !== null));
      };

      fetchColors();
    }
  }, [data]);

  const media = data.prismicProject.data.body.map((item, index) => {
    if (item.slice_type === `image`) {
      return (
        <div className="slide" key={`single_image_${index}_${item.id}`}>
          <SingleImage
            data={data.prismicProject.data}
            image={item.primary.image}
            imageSize={item.primary.image_size}
            id={item.id}
            index={index}
            currentInnerSlide={currentGallerySlide}
            currentOuterSlide={1}
            outerIndex={1}
          />
        </div>
      );
    }

    if (item.slice_type === `video`) {
      return (
        <div
          className="slide"
          key={`single_video_${index}_${item.id}`}
          style={{
            backgroundColor: `#fff0f0`,
          }}
        >
          <SingleVideo
            data={data.prismicProject.data}
            video={item.primary.video}
            videoSize={item.primary.video_size}
            id={item.id}
            index={index}
            currentInnerSlide={currentGallerySlide}
            currentOuterSlide={1}
            outerIndex={1}
          />
        </div>
      );
    }
  });

  return (
    <>
      <PageSEO
        title={data.prismicProject.data.title.text}
        description={data.prismicProject.data.text.text}
        image={data.prismicProject.data.thumbnail.url}
        url={data.prismicProject.url}
      />
      <Page height={height}>
        <Gallery
          media={media}
          isPanelOpen={isPanelOpen}
          setIsPanelOpen={setIsPanelOpen}
          setCurrentGallerySlide={setCurrentGallerySlide}
          currentGallerySlide={currentGallerySlide}
        />

        <div
          className={`caption-container ${
            isPrismicPreview ? `layout-preview` : ``
          }`}
        >
          <button onClick={() => setIsPanelOpen(true)} type="button">
            <span>
              <PrismicRichText
                field={data.prismicProject.data.title.richText}
              />
            </span>

            <PlusIcon fill={`#000`} />
          </button>
        </div>

        <Panel
          title={data.prismicProject.data.title.richText}
          additionalInfo={data.prismicProject.data.additional_info.richText}
          text={data.prismicProject.data.text.richText}
          isPanelOpen={isPanelOpen}
          setIsPanelOpen={setIsPanelOpen}
          height={height}
          size={data.prismicProject.data.information_panel_size}
        />
      </Page>
    </>
  );
};

export default withPrismicPreview(Project);

export const query = graphql`
  query Project($id: String) {
    prismicProject(id: { eq: $id }) {
      _previewable
      url
      id
      data {
        title {
          richText
          text
        }
        text {
          richText
          text
        }
        additional_info {
          richText
          text
        }
        information_panel_size
        thumbnail {
          url
        }
        body {
          ... on PrismicProjectDataBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                url
              }
              image_size
            }
          }
          ... on PrismicProjectDataBodyVideo {
            id
            slice_type
            primary {
              video_size
              video {
                id
                width
                html
                height
              }
            }
          }
        }
      }
    }
  }
`;
