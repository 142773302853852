import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 35px 40px;

  z-index: 200;

  width: fit-content;

  & nav {
    display: flex;
    flex-direction: row;

    & a {
      font-size: 19px;
      display: none;
      margin: 0 40px 0 0;

      &.current {
        display: block;
      }
    }

    &:hover,
    &:focus {
      & a {
        display: flex;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 18px;

    & nav {
      & a {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
`;

export const DesktopMenu = () => (
  <HeaderWrapper>
    <nav>
      <Link to={`/`} activeClassName="current">
        Henry Coleman
      </Link>

      {/* <Link to={`/projects/`} activeClassName="current" partiallyActive>
        Projects
      </Link> */}

      <Link to={`/about/`} activeClassName="current">
        About
      </Link>
    </nav>
  </HeaderWrapper>
);
