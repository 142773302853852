exports.linkResolver = (doc) => {
  if (doc.type === "home") {
    return `/`;
  }

  if (doc.type === "project") {
    return `/projects/${doc.uid}/`;
  }

  return `/${doc.uid}/`;
};
