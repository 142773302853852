import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 35px 0;
  padding: 0 40px;

  height: calc(${(props) => props.height}px - 35px - 40px);

  & .grid {
    display: grid;
    grid-column-gap: 40px;

    height: 100%;
  }

  & .grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  & .links-container {
    grid-column: 1 / 3;
    align-self: flex-end;

    & ol {
      & li {
        & p {
          margin: 0;
        }
      }
    }
  }

  & .text-container {
    grid-column: 3 / 7;

    max-width: 890px;
    margin: 0 0 0 auto;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    margin: 80px 0 60px 0;
    padding: 0 18px;

    & .text-container {
      margin: 1em 0 0 0;
    }

    & .grid {
      display: block;
      grid-column-gap: unset;
    }
    & .grid-6 {
      grid-template-columns: unset;
    }
  }
`;

const About = ({ data }) => {
  // Window Height
  const height = use100vh();

  useEffect(() => {
    document.body.classList.remove(`light-palette`);
    document.body.classList.remove(`dark-palette`);
  }, []);

  const links = data.prismicAbout.data.links.map((link, index) => (
    <li key={`single_about_link_${index}`}>
      <PrismicRichText field={link.link.richText} />
    </li>
  ));

  return (
    <>
      <PageSEO
        title={data.prismicAbout.data.title.text}
        description={data.prismicAbout.data.text.text}
        image={null}
        url={data.prismicAbout.url}
      />

      <Page height={height}>
        <div className="grid grid-6">
          <div className="links-container">
            <ol>{links}</ol>
          </div>

          <div className="text-container">
            <PrismicRichText field={data.prismicAbout.data.text.richText} />
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(About);

export const query = graphql`
  {
    prismicAbout {
      _previewable
      url
      id
      data {
        title {
          text
          richText
        }
        text {
          text
          richText
        }
        links {
          link {
            richText
          }
        }
      }
    }
  }
`;
