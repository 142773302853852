import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Components
import { SingleImage } from "../project/single-image";
import { SingleVideo } from "../project/single-video";
import { Gallery } from "../images/gallery";

const Page = styled.div`
  & .fp-section {
    height: ${(props) => props.height}px;
    overflow: hidden;
  }

  & .gatsby-image-wrapper {
    height: ${(props) => props.height}px;
  }

  & .slide {
    height: ${(props) => props.height}px;
    overflow: hidden;

    & .fp-overflow {
      height: ${(props) => props.height}px;
      overflow: hidden;
    }
  }

  & .video-slide,
  & .image-slide {
    position: relative;
    z-index: 1;

    &.video-size-full-bleed,
    &.image-size-full-bleed {
      height: ${(props) => props.height}px;

      & img {
        height: ${(props) => props.height}px;
        object-fit: cover;
      }
    }

    &.video-size-padded,
    &.image-size-padded {
      height: ${(props) => props.height}px;

      /* max-height: calc(${(props) => props.height}px - 120px - 120px); */
      padding: 120px;

      & img {
        max-height: calc(${(props) => props.height}px - 120px - 120px);
        object-fit: contain !important;
      }

      @media (max-width: 900px) {
        max-height: calc(${(props) => props.height}px - 60px - 60px);
        padding: 60px;

        & img {
          max-height: calc(${(props) => props.height}px - 60px - 60px);
        }
      }

      @media (max-width: 600px) {
        max-height: ${(props) => props.height}px;
        padding: 20px;

        & img {
          max-height: calc(${(props) => props.height}px - 20px - 20px);
        }
      }
    }
  }

  & .fp-arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    height: 100%;
    width: 50%;

    z-index: 10;

    margin: 0;
    transform: unset;
    user-select: unset;

    &.fp-prev,
    &.fp-next {
      border-width: unset;
      border-color: unset;
      border: 0;
    }
  }
`;

export const SlideshowMode = ({ data, isPrismicPreview }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [currentGallerySlide, setCurrentGallerySlide] = useState(0);
  const [colorData, setColorData] = useState([]);

  // Window Height
  const height = use100vh();

  const fetchColorPalette = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };

  const getColorPalettes = async (url, type) => {
    if (type === `image`) {
      try {
        const res = await fetchColorPalette(url);
        return {
          imageColor:
            res?.dominant_colors?.vibrant?.hex !== undefined
              ? res?.dominant_colors?.vibrant?.hex
              : res?.dominant_colors?.vibrant_light?.hex,
          imageLuminance: res.average_luminance,
        };
      } catch (e) {
        console.log(e.message);
        return null;
      }
    } else {
      return {
        imageColor: `#fff0f0`,
        imageLuminance: 1,
      };
    }
  };

  useEffect(() => {
    const fetchColors = async () => {
      const colorPromises = data.prismicHome.data.body.map((item) => {
        if (item.slice_type === `image`) {
          return getColorPalettes(
            `${item.primary.image.url}&palette=json`,
            `image`
          );
        } else {
          return getColorPalettes(``, `video`);
        }
      });

      const colors = await Promise.all(colorPromises);
      setColorData(colors.filter((color) => color !== null));
    };

    fetchColors();
  }, [data]);

  useEffect(() => {
    if (colorData[currentGallerySlide] !== undefined) {
      if (colorData[currentGallerySlide].imageLuminance > 0.55) {
        document.body.classList.remove(`light-palette`);
        document.body.classList.add(`dark-palette`);
      } else {
        document.body.classList.remove(`dark-palette`);
        document.body.classList.add(`light-palette`);
      }
    }
  }, [currentGallerySlide, colorData]);

  const media = data.prismicHome.data.body.map((item, index) => {
    if (item.slice_type === `image`) {
      return (
        <div
          className="slide"
          key={`single_image_${index}_${item.id}`}
          style={{
            backgroundColor:
              colorData[index] !== undefined
                ? colorData[index].imageColor
                : `#fff0f0`,
          }}
        >
          <SingleImage
            data={data.prismicHome.data}
            image={item.primary.image}
            imageSize={item.primary.image_size}
            id={item.id}
            index={index}
            currentInnerSlide={currentGallerySlide}
            currentOuterSlide={1}
            outerIndex={1}
          />
        </div>
      );
    }

    if (item.slice_type === `video`) {
      return (
        <div
          className="slide"
          key={`single_video_${index}_${item.id}`}
          style={{
            backgroundColor: `#fff0f0`,
          }}
        >
          <SingleVideo
            data={data.prismicHome.data}
            video={item.primary.video}
            videoSize={item.primary.video_size}
            id={item.id}
            index={index}
            currentInnerSlide={currentGallerySlide}
            currentOuterSlide={1}
            outerIndex={1}
          />
        </div>
      );
    }
  });

  return (
    <Page height={height}>
      <Gallery
        media={media}
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
        setCurrentGallerySlide={setCurrentGallerySlide}
        currentGallerySlide={currentGallerySlide}
      />
    </Page>
  );
};
