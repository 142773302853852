import React from "react";
import { graphql } from "gatsby";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { PageSEO } from "../components/seo/page-seo";
import { SlideshowMode } from "../components/homepage/slideshow";
import { ProjectsMode } from "../components/homepage/projects";

const Index = ({ data, isPrismicPreview }) => {
  return (
    <>
      <PageSEO
        title={null}
        description={null}
        image={null}
        url={data.prismicHome.url}
      />

      {data.prismicHome.data.homepage_mode === `Slideshow` ? (
        <SlideshowMode isPrismicPreview={isPrismicPreview} data={data} />
      ) : (
        <ProjectsMode isPrismicPreview={isPrismicPreview} data={data} />
      )}
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHome {
      _previewable
      url
      id
      data {
        title {
          text
        }
        homepage_mode
        body {
          ... on PrismicHomeDataBodyImage {
            id
            slice_type
            primary {
              image_size
              image {
                alt
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  srcSetMaxWidth: 2000
                )
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicHomeDataBodyVideo {
            id
            slice_type
            primary {
              video_size
              video {
                id
                width
                html
                height
              }
            }
          }
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    richText
                    text
                  }
                  text {
                    richText
                    text
                  }
                  additional_info {
                    richText
                    text
                  }
                  information_panel_size
                  body {
                    ... on PrismicProjectDataBodyImage {
                      id
                      slice_type
                      primary {
                        image {
                          alt
                          url
                          gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                        image_size
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
