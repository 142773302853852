import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 18px;

  z-index: 200;

  &.bg {
    background-color: #ffffff;
  }

  & nav {
    display: flex;
    flex-direction: row;

    width: 100%;

    & a {
      font-size: 18px;

      margin: 0 40px 0 0;
      line-height: 1.1;
    }

    & button {
      font-size: 18px;
    }

    & ol {
      display: flex;
      flex-direction: row;

      & li {
        line-height: 1.1;
      }
    }
  }
`;

export const MobileMenu = ({ location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <HeaderWrapper
      className={
        location === `/` || location.includes(`/projects/`) ? `no-bg` : `bg`
      }
    >
      <nav>
        {isMenuOpen ? (
          <Link to={`/`} activeClassName="current">
            Henry Coleman
          </Link>
        ) : (
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} type="button">
            Henry Coleman
          </button>
        )}

        {isMenuOpen && (
          <ol>
            {/* <li>
              <Link to={`/projects/`} activeClassName="current" partiallyActive>
                Projects
              </Link>
            </li> */}

            <li>
              <Link to={`/about/`} activeClassName="current">
                About
              </Link>
            </li>
          </ol>
        )}
      </nav>
    </HeaderWrapper>
  );
};
